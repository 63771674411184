var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"id":"dashboard-view","fluid":"","tag":"section"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card',{staticClass:"pr-2 pl-2"},[_c('div',{staticClass:"pt-3 pb-3 d-flex justify-space-between"},[_c('div',[_c('span',{staticClass:"pl-2 pr-4 pt-2 pb-2 header-job"},[_c('span',{staticClass:"mdi mdi-chevron-right pr-1"}),_vm._v("基本的な情報 ")])])]),_c('v-card-text',{staticClass:"pl-10 pr-10"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"text-button",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('messages.factoryName'))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])])]),_c('v-col',{attrs:{"cols":"8","sm":"8","md":"8"}},[_c('div',{staticClass:"align-self-center"},[_c('v-autocomplete',{attrs:{"dense":"","items":_vm.customers,"item-text":function (item) { return item.code == null ? item.name : item.code +' - '+ item.name; },"item-value":"id","outlined":"","no-data-text":_vm.$t('table.messages.no_data')},model:{value:(_vm.customerId),callback:function ($$v) {_vm.customerId=$$v},expression:"customerId"}})],1)]),_c('v-col',{staticClass:"text-button",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("期間 "),_c('span',{staticClass:"red--text"},[_vm._v("*")])])]),_c('v-col',{attrs:{"cols":"8","sm":"8","md":"8"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","type":"text","single-line":"","outlined":"","error-messages":errors[0]},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-button",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("一日の人数 "),_c('span',{staticClass:"red--text"},[_vm._v("*")])])]),_c('v-col',{attrs:{"cols":"8","sm":"8","md":"8"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","type":"text","single-line":"","outlined":"","error-messages":errors[0]},model:{value:(_vm.numOfWorker),callback:function ($$v) {_vm.numOfWorker=$$v},expression:"numOfWorker"}})]}}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"text-button",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("ジョブ名 "),_c('span',{staticClass:"red--text"},[_vm._v("*")])])]),_c('v-col',{attrs:{"cols":"8","sm":"8","md":"8"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","type":"text","single-line":"","outlined":"","error-messages":errors[0]},model:{value:(_vm.namejob),callback:function ($$v) {_vm.namejob=$$v},expression:"namejob"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-button",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("出勤曜日 "),_c('span',{staticClass:"red--text"},[_vm._v("*")])])]),_c('v-col',{attrs:{"cols":"8","sm":"8","md":"8"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"workDay","name":"workDay","dense":"","single-line":"","outlined":"","error-messages":errors[0]},model:{value:(_vm.workDay),callback:function ($$v) {_vm.workDay=$$v},expression:"workDay"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-button",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("条件 "),_c('span',{staticClass:"red--text"},[_vm._v("*")])])]),_c('v-col',{attrs:{"cols":"8","sm":"8","md":"8"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","type":"text","single-line":"","outlined":"","error-messages":errors[0]},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]}}],null,true)})],1)],1)],1)],1)],1)],1)],1),_c('v-card',{staticClass:"pr-2 pl-2 mt-4"},[_c('div',{staticClass:"pt-3 pb-3 d-flex justify-space-between"},[_c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"pl-2 pr-4 pt-2 pb-2 header-job"},[_c('span',{staticClass:"mdi mdi-chevron-right pr-1"}),_vm._v("シフト設定 ")])]),_c('div',[_c('span',{staticClass:"pr-2"},[(!_vm.isShowBtnAdd)?_c('v-btn',{attrs:{"small":"","fab":"","dark":"","depressed":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.isShowBtnAdd = true}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1):_c('v-btn',{attrs:{"small":"","fab":"","dark":"","depressed":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.isShowBtnAdd = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-minus ")])],1)],1)])]),_c('v-card-text',{staticClass:"pl-10 pr-10"},[_c('v-container',[_c('v-row',_vm._l((_vm.shifts),function(shift,shiftIndex){return _c('v-col',{key:shiftIndex,attrs:{"cols":"6","sm":"6","md":"6"}},[(!shift.deleted)?_c('span',[_c('v-row',[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"1"}},[_c('div',{staticClass:"mt-1 pl-4"},[_c('v-btn',{attrs:{"color":"secondary","fab":"","outlined":"","x-small":"","dark":""},on:{"click":function($event){return _vm.openDialogDeleteShift(shiftIndex)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"align-self-center job-time"},[_c('div',{staticClass:"text-button text-center"},[_c('span',[_vm._v(_vm._s(shift.shiftStartAt))]),_vm._v(" ~ "),_c('span',[_vm._v(_vm._s(shift.shiftEndAt))])])])]),_c('v-col',{attrs:{"cols":"2"}},[_c('validation-provider',{attrs:{"name":"fromtimeValidation","rules":"checktime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('the-mask',{staticClass:"inputMask",attrs:{"mask":"##:##","masked":"","error-messages":errors[0],"placeholder":"hh:mm","required":""},model:{value:(shift.planBreakTime),callback:function ($$v) {_vm.$set(shift, "planBreakTime", $$v)},expression:"shift.planBreakTime"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.statusSubmit,"item-text":"text","item-value":"value","outlined":"","dense":"","no-data-text":_vm.$t('table.messages.no_data'),"disabled":_vm.checkWorkTimeType(shift.shiftStartAt, shift.shiftEndAt)},model:{value:(shift.worktimeType),callback:function ($$v) {_vm.$set(shift, "worktimeType", $$v)},expression:"shift.worktimeType"}})],1)],1)],1):_vm._e()])}),1),(_vm.isShowBtnAdd)?_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-row',[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"align-self-center"},[_c('validation-provider',{ref:"fromtime",attrs:{"name":"fromtimeValidation","rules":"checktime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","single-line":"","outlined":"","error-messages":errors[0],"placeholder":"hh:mm"},on:{"blur":function($event){return _vm.handleShift()},"focus":function($event){_vm.isActiveSave = true}},model:{value:(_vm.shiftStartAt),callback:function ($$v) {_vm.shiftStartAt=$$v},expression:"shiftStartAt"}})]}}],null,true)})],1)]),_c('v-col',{staticClass:"text-button",attrs:{"cols":"1"}},[_vm._v(" ～ ")]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"align-self-center"},[_c('validation-provider',{attrs:{"name":"totimeValidation","rules":"checktime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","single-line":"","outlined":"","placeholder":"hh:mm","error-messages":errors[0]},on:{"blur":function($event){return _vm.handleShift()},"focus":function($event){_vm.isActiveSave = true}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleShift()}},model:{value:(_vm.shiftEndAt),callback:function ($$v) {_vm.shiftEndAt=$$v},expression:"shiftEndAt"}})]}}],null,true)})],1)])],1)],1)],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-row',{staticClass:"d-flex justify-end mt-3"},[_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"large":"","block":"","color":"info","disabled":_vm.isActiveSave},on:{"click":function($event){return handleSubmit(_vm.handleSubmitForm)}}},[_c('span',{staticClass:"text-h5 pr-3 pl-3"},[_vm._v(" "+_vm._s(_vm.$t('messages.signup')))])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"large":"","block":"","color":"info"},on:{"click":function($event){return _vm.$router.push({path: '/jobs'})}}},[_c('span',{staticClass:"text-h5 pr-3 pl-3"},[_vm._v(" "+_vm._s(_vm.$t('messages.close')))])])],1)],1)],1)],1)]}}])}),_c('dialog-delete-shift',{attrs:{"dialog-delete-shift":_vm.dialogDeleteShift,"index":_vm.indexShift},on:{"removeShift":function($event){return _vm.removeShift()},"closeDialogDelete":function($event){return _vm.closeDialogDelete()}}}),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('messages.close'))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }